import {DEFAULT_BOOK_URL, LINK_PARAM_DELIMITER} from '../const';
import {getLinkParamObject} from '../utils/tools';

const LINK_PARAM = getLinkParamObject(
    'hotel_id',
    'room-type',
    'date',
    'nights',
    'adults',
    'tl-offer'
);

export const getLink = (linkParams, bookUrl, elementModel, priceInfoModel) => {
    const paramValue = getLinkParamObject(
        elementModel.getHotel(),
        elementModel.getRoomType(),
        priceInfoModel.getData(),
        priceInfoModel.getMinLos(),
        elementModel.getAdults(),
        priceInfoModel.getRatePlaneCode()
    );
    const paramCodes = linkParams !== '' ? linkParams.split(LINK_PARAM_DELIMITER) : [];
    const urlParams = [];

    if (!bookUrl) {
        bookUrl = DEFAULT_BOOK_URL;
    }

    if (!paramCodes.length) {
        return paramValue.room ?
            `${bookUrl}?${LINK_PARAM.room}=${paramValue.room}` :
            `${bookUrl}?${LINK_PARAM.hotel}=${paramValue.hotel}`;
    }

    paramCodes.forEach((code) => {
        if (paramValue[code]) {
            urlParams.push(`${LINK_PARAM[code]}=${paramValue[code]}`);
        }
    });

    return `${bookUrl}?${urlParams.join('&')}`;
};
