import {PRICES_CACHE_FILE} from '../const';
import {getNowTimeStamp} from './tools';

const FETCH_PRICE_ERROR = 'Could\'t fetch price';

export const fetchPrices = async () => {
    const result = await fetch(`${PRICES_CACHE_FILE}?${getNowTimeStamp()}`);

    if (!result.ok) {
        console.error(FETCH_PRICE_ERROR);
    }

    return await result.json();
};
